import './Home.css';
import React from "react";
import TextTransition, { presets } from "react-text-transition";
import Marquee from "react-fast-marquee";
import headcountIcon from './images/headcount.svg';
import dashboard from './images/dashboard.svg';
import approvalProcess from './images/approval-process.svg';
import communication from './images/communication.svg';
import integrate from './images/integrate.svg';
import easy from './images/easy.svg';
import analytics from './images/analytics.svg';
import hr from './images/hr.svg';
import product from './images/product.svg';
import marketing from './images/marketing.svg';
import legal from './images/legal.svg';
import design from './images/design.svg';
import bizops from './images/bizops.svg';
import sales from './images/sales.svg';
import research from './images/research.svg';
import workflow from './images/workflow.svg';
import marketingSpend from './images/marketing-spend.svg';
import backfill from './images/backfill.svg';
import access from './images/access.svg';
import press from './images/press.svg';
import copy from './images/copy.svg';
import offer from './images/offer.svg';
import logo from '../logo.svg';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

const TEXTS = [
    "anything",
    "HR",
    "Product",
    "Legal",
    "Marketing",
    "Sales",
    "Agencies",
    "Design",
    "Engineering"
  ];

export default function App() {
    const [index, setIndex] = React.useState(0);

    React.useEffect(() => {
        const intervalId = setInterval(() =>
        setIndex(index => index + 1),
        3500 // every 3 seconds
        );
        return () => clearTimeout(intervalId);
    }, []);
  return (
    <div>
        <Helmet>
            <title>Outcome | Workflow Automation</title>
        </Helmet>
    <div className='texture'>
        <div className='container'>
            <div className='right-padding-100'>
                <h1>Automate your workflows and approval processes for 
                    <span>
                        <TextTransition springConfig={presets.wobbly}>
                            {TEXTS[index % TEXTS.length]}
                        </TextTransition>
                    </span>
                </h1>
            </div>
            
        </div>
            <div className='marquee-container'>
                <Marquee gradient={false}>
                    <div className='squircle-purple squircle'>
                        <img src={headcountIcon}/>
                        <p>Headcount Request Flow</p>
                    </div>
                    <div className='squircle-pink up-squircle squircle'>
                        <img src={marketingSpend}/>
                        <p>Marketing Spend Request</p>
                    </div>
                    <div className='squircle-blue squircle'>
                        <img src={backfill}/>
                        <p>Backfill Employee Request</p>
                    </div>
                    <div className='squircle-green up-squircle squircle'>
                        <img src={access}/>
                        <p>Software Access Request</p>
                    </div>
                    <div className='squircle-red squircle'>
                        <img src={press}/>
                        <p>Press Release Approval</p>
                    </div>
                    <div className='squircle-orange up-squircle squircle'>
                        <img src={copy}/>
                        <p>Website Copy Review</p>
                    </div>
                    <div className='squircle-purple squircle'>
                        <img src={offer}/>
                        <p>Offer Letter</p>
                    </div>
        
                    <div className='squircle-pink up-squircle squircle'>
                        <img src={marketingSpend}/>
                        <p>Marketing Spend Request</p>
                    </div>
                    <div className='squircle-blue squircle'>
                        <img src={backfill}/>
                        <p>Backfill Employee Request</p>
                    </div>
                    <div className='squircle-green up-squircle squircle'>
                        <img src={access}/>
                        <p>Software Access Request</p>
                    </div>
                    <div className='squircle-red squircle'>
                        <img src={press}/>
                        <p>Press Release Approval</p>
                    </div>
                    <div className='squircle-orange up-squircle squircle'>
                        <img src={copy}/>
                        <p>Website Copy Review</p>
                    </div>
                    <div className='squircle-purple squircle'>
                        <img src={offer}/>
                        <p>Offer Letter</p>
                    </div>
                    <div className='squircle-pink up-squircle squircle'>
                        <img src={headcountIcon}/>
                        <p>Headcount Request Flow</p>
                    </div>
                </Marquee>
            </div>
            <div className='container center-text'><a className='button-sign-up' href='https://app.tryoutcome.com'>Get Started Free</a></div>
        </div>

        <div className='clearfix'>
            <div className='why-outcome-section container clearfix'>
                <span className='label'>Why Outcome</span>
                <h2>Outcome is the all-in-one platform for running request and approval processes at scale.</h2>
            </div>
            <div className='container clearfix'>
                <div className='listWrapper clearfix'>
                    <ul>
                        <li><span><img src={approvalProcess}/></span>Customizable workfow & approval process</li>
                        <li><span><img src={communication}/></span>Centralized communication</li>
                        <li><span><img src={integrate}/></span>Integrate the tools you use</li>
                        <li><span><img src={easy}/></span>Easy to use dashboard for employees</li>
                        <li><span><img src={analytics}/></span>Advanced reporting and analytics</li>
                        <li className='no-border'>
                            <a href='https://app.tryoutcome.com' className='button-sign-up'>Get Started</a>
                        </li>
                    </ul>
                </div>
                <div className='screenshot clearfix'><img src={dashboard}/></div>
            </div>
        </div>

        <div className='container solutions-section'>
            <span className='label'>Solutions</span>
            <h2>For every team in your company.</h2>
        </div>
        <div className='container clearfix'>
            <div className='clearfix'>
                <div className='solution-item'>
                    <img src={hr}/>
                    <h3>Human Resources</h3>
                    <p>Handle operations like headcount planning, budget requests and more.</p>
                </div>
                <div className='solution-item'>
                    <img src={marketing}/>
                    <h3>Marketing</h3>
                    <p>Get copy reviewed quickly, request ad spend, and more.</p>
                </div>
                <div className='solution-item'>
                    <img src={product}/>
                    <h3>Product</h3>
                    <p>Review a business canvas, get aligned, make quick decisions.</p>
                </div>
                <div className='solution-item'>
                    <img src={legal}/>
                    <h3>Legal</h3>
                    <p>Review press releases, contracts, and compliance.</p>
                </div>
            </div>

            <div className='clearfix'>
                <div className='solution-item'>
                    <img src={design}/>
                    <h3>Design</h3>
                    <p>Design reviews, quicker decisions, and alignment.</p>
                </div>
                <div className='solution-item'>
                    <img src={bizops}/>
                    <h3>Business Operations</h3>
                    <p>Review strategic priorities, next steps, analytics.</p>
                </div>
                <div className='solution-item'>
                    <img src={research}/>
                    <h3>Research</h3>
                    <p>Research plans, test scripts, and quicker decisions.</p>
                </div>
                <div className='solution-item'>
                    <img src={sales}/>
                    <h3>Sales</h3>
                    <p>Contract negotiations, inbound requests, and more.</p>
                </div>
            </div>
            <h2 className='block-text-center'>...and more!</h2>
        </div>

        {/* <div className='divider'></div>
        <div className='container'>
            <span className='label'>Integrations</span>
            <h2>Integrate the tools your company are already using</h2>
        </div> */}
        <div className='divider'></div>
        <div className='container cta-section'>
            <span className='label'>Get Started</span>
            <h2>Ready to get the outcome your looking for?</h2>
            <div className='clearfix'>
                <a href='https://app.tryoutcome.com' className='button-sign-up'>Get Started Free</a>
            </div>
            <img src={workflow}/>
        </div>
        <div className='divider-no-margin'></div>
        <div className='container footer-section clearfix'>
            <img src={logo}/>
            <span>© 2022 Try Outcome</span>
        </div>
    </div>
  );
}
