import './Home.css';
import logo from '../logo.svg';
import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import enterprise from './images/enterprise.svg';
import growth from './images/growth.svg';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import {Helmet} from "react-helmet";
import Outcome from './images/outcome-workflow-automation.png';

export default function App() {
  return (
    <div>
        <Helmet>
            <title>Outcome | Pricing</title>
            <meta property='og:image' content={Outcome}/>
        </Helmet>
        <div className='container pricing-container'>
            <span className='label'>Pricing</span>
            <h1 className='no-margin-bottom'>Plans designed for growth</h1>
        </div>

        <div className='container no-padding-top'>
            <Tabs>
                <TabList>
                    <Tab>Monthly</Tab>
                    <Tab>Annual<span>30% Off</span></Tab>
                </TabList>

                <TabPanel>
                    <div className='pricing-columns'>
                        <div className='pricing-column'>
                            <img src={growth}/>
                            <div className="title">Growth</div>
                            <div className='price'>$10</div>
                            <p className='pricing-desc'>Per Member / Month</p>
                            <p className='pricing-desc-dark'>Perfect for startups and smaller teams that have basic workflows and processes.</p>
                            <a href='https://app.tryoutcome.com' className='pricing-button-second'>Get Started Free</a>
                        </div>
                        <div className='pricing-column enterprise-column'>
                            <img src={enterprise}/>
                            <div className="title">Enterprise</div>
                            <div className='price'>Contact Us</div>
                            <p className='pricing-desc'>Schedule Demo</p>
                            <p className='pricing-desc-dark'>Perfect for larger teams and companies that have complex workflows and approval processes.</p>
                            <a href='https://app.tryoutcome.com' className='pricing-button'>Get Started Free</a>
                        </div>
                    </div>
                </TabPanel>

                <TabPanel>
                    <div className='pricing-columns'>
                        <div className='pricing-column'>
                            <img src={growth}/>
                            <div className="title">Growth</div>
                            <div className='price'>$7</div>
                            <p className='pricing-desc'>Per Member / Month Paid Annually</p>
                            <p className='pricing-desc-dark'>Perfect for startups and smaller teams that have basic workflows and processes.</p>
                            <a href='https://app.tryoutcome.com' className='pricing-button-second'>Get Started Free</a>
                        </div>
                        <div className='pricing-column enterprise-column'>
                            <img src={enterprise}/>
                            <div className="title">Enterprise</div>
                            <div className='price'>Contact Us</div>
                            <p className='pricing-desc'>Schedule Demo</p>
                            <p className='pricing-desc-dark'>Perfect for larger teams and companies that have complex workflows and approval processes.</p>
                            <a href='https://app.tryoutcome.com' className='pricing-button'>Get Started Free</a>
                        </div>
                    </div>
                </TabPanel>

                <p className='free-tier'>Want to try Outcome out first? <a href='https://app.tryoutcome.com' className='priceLink'>Get started with one workflow free</a></p>

            </Tabs>



            <Accordion>
                <h2>Frequently Asked Questions</h2>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What is a member?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            A member is any user that has access to the app regardless of their role.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Can you try Outcome for free?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Yes, you can sign up for Outcome for free to try it out and get one workflow for free!
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Can I get a demo of Outcome?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Yes, contact us anytime to set up a time and we'll walk through the product with you.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>

        </div>

        <div className='center-text'><a href='https://app.tryoutcome.com' className='linkTo'>Get Started Free</a></div>
        <br/><br/><br/>
        <div className='divider-no-margin'></div>
        <div className='container footer-section clearfix'>
            <img src={logo}/>
            <span>© 2022 Try Outcome</span>
        </div>

    </div>
  );
}