import './App.css';
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import logo from './logo.svg';
import Home from './Pages/Home';
import SignUp from './Pages/SignUp';
import Pricing from './Pages/Pricing';
import Product from './Pages/Product';
import { hotjar } from 'react-hotjar';

hotjar.initialize(3269033, 6);

// This site has 3 pages, all of which are rendered
// dynamically in the browser (not server rendered).
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.

export default function App() {
  return (
    <Router>
      <div>
        <div className='navigation'>

          <Link to="/" className='logo'><img src={logo} alt="Logo"/></Link>

          <ul>
            <li>
              <Link to="/product">Product</Link>
            </li>
            <li>
              <Link to="/pricing">Pricing</Link>
            </li>
            {/* <li>
              <Link to="/about">About</Link>
            </li> */}
          </ul>
          <a className='button' href='https://app.tryoutcome.com'>Get Started</a>
          <a className='link' href='https://app.tryoutcome.com/login'>Log In</a>
        </div>


        {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/product">
            <Product />
          </Route>
          <Route path="/pricing">
            <Pricing />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/sign-up">
            <SignUp />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

// You can think of these components as "pages"
// in your app.

function HomePage() {
  return (
    <div className='container'>
      <home/>
    </div>
  );
}

function ProductPage() {
  return (
    <div>
      <Product/>
    </div>
  );
}

function pricing() {
  return (
    <div>
      <Pricing/>
    </div>
  );
}

function About() {
  return (
    <div>
      <h2>About</h2>
    </div>
  );
}

function signup() {
  return (
    <div>
      <SignUp/>
    </div>
  );
}