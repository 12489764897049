import './Home.css';
import React, { useState, useEffect } from "react";
import Product from './images/product-sheet.svg';
import Busy from './images/busy.svg';
import Process from './images/process.svg';
import Dash from './images/dash.svg';
import Integration from './images/integration.svg';
import Conditional from './images/conditional.svg';
import logo from '../logo.svg';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
  import {Helmet} from "react-helmet";

export default function App() {
    const [div1BackgroundColor, setDiv1BackgroundColor] = useState('#FFF9E7');
    const [div2BackgroundColor, setDiv2BackgroundColor] = useState('#FFEBFB');
    const [div3BackgroundColor, setDiv3BackgroundColor] = useState('#E2F3EA');
    const [div4BackgroundColor, setDiv4BackgroundColor] = useState('#F7F5FE');

    const desiredId = '#targetId';

    useEffect(() => {
        const targetElement = document.querySelector(desiredId);

        // Check if the user has reached the desired id
        if (targetElement) {
        const bounding = targetElement.getBoundingClientRect();
        if (
            bounding.top >= 0 &&
            bounding.left >= 0 &&
            bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
            bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        ) {
            // Update the background colors
            setDiv1BackgroundColor('#FFEBFB');
            setDiv2BackgroundColor('#FFF9E7');
            setDiv3BackgroundColor('#F7F5FE');
            setDiv4BackgroundColor('#E2F3EA');
        }
        }
    }, [desiredId]);


    return (
      <div>
        <Helmet>
            <title>Outcome | Product Overview</title>
        </Helmet>
        <div className='texture'>
            <div className='container'>
                <div className='product-page-hero'>
                    <div className='padding-top-large col'>
                        <span className='label'>Product</span>
                        <h1>Process management and workflow automation that’s easy to use</h1>
                        <p>With Outcome, you can integrate the tools you use, collaborate with your team and company, scale your processes, all with an easy-to-use interface.</p>
                        <a href='https://app.tryoutcome.com' className='product-button'>Get Started Free</a>
                    </div>
                    <div className='product-page-hero-img'>
                        <img src={Product}/>
                    </div>
                </div>
               
            </div>
        </div>
        <div className='container'>
            <div className='process'>
                <img src={Busy}/>
                <h2>Remove the busy work</h2>
                <img className="process-img" src={Process}/>
            </div>
        </div>
        <div className='divider'></div>
        <div className='container'>
            <div className='product-section'>
                <div className='padding-top-xlarge'>
                    <span className='label'>Any process works for you</span>
                    <h2>Create collaborative processes, workflows, and approvals that work for your business and teams</h2>
                </div>
                <div className='color-bg' id='div1' style={{ backgroundColor: div1BackgroundColor }}>
                    <img src={Dash}/>
                </div>
            </div>
            <div className='product-section'>
                <div className='padding-top-xlarge'>
                    <span className='label'>Integrate the tools you already use</span>
                    <h2>Integrate the tools you use already to create a seamless experience</h2>
                </div>
                <div className='color-bg' id='div2' style={{ backgroundColor: div2BackgroundColor }}>
                    <img src={Integration}/>
                </div>
            </div>
            <div className='product-section'>
                <div className='padding-top-xlarge'>
                    <span className='label'>Conditional Logic</span>
                    <h2>Create the logic that works for your team regardless of workflow using our conditional logic</h2>
                </div>
                <div className='color-bg' id='div3' style={{ backgroundColor: div3BackgroundColor }}>
                    <img src={Conditional}/>
                </div>
            </div>
            <div className='product-section'>
                <div className='padding-top-xlarge'>
                    <span className='label'>Reporting</span>
                    <h2>Use our advanced reporting and analytics to see how processes are performing and report key information for your business</h2>
                </div>
                <div className='color-bg' id='div4' style={{ backgroundColor: div4BackgroundColor }}>
                    <img src={Product}/>
                </div>
            </div>
        </div>

        <div className='divider'></div>
        <div className='container cta-section'>
            <span className='label'>Get Started</span>
            <h2>Ready to get the outcome your looking for?</h2>
            <div className='clearfix'>
                <a href='https://app.tryoutcome.com' className='footer-button'>Get Started Free</a>
            </div>
        </div>
        <div className='divider-no-margin'></div>
        <div className='container footer-section clearfix'>
            <img src={logo}/>
            <span>© 2022 Try Outcome</span>
        </div>
      </div>
    );
  }